import React from "react";
import SEO from "../../components/seo";
import HorizontalLine from "../../components/horizontal-line";
import "./about.scss";

const AboutUs = (props) => (
    <div location={props.location}>
        <SEO title="O nas" language="pl"/>
        <div className="about">
            <div className="container">
                <div className="about__title">
                    <HorizontalLine classname='black'/>
                    <h2 className="about-us-title">
                        Kim jesteśmy i dlaczego warto z nami <br/>współpracować?
                    </h2>
                    <HorizontalLine classname="black"/>
                </div>
                <div className="about__description">
                    <div className="squares-column">
                        <div className="square white"/>
                        <div className="square orange"/>
                        <div className="square white"/>
                    </div>
                    <div className="about__feature">
                        <p>
                            Programujemy. Projektujemy. Pozycjonujemy i serwisujemy strony internetowe.
                            Stawiamy przede wszystkim na skuteczną komunikację, uważnie słuchamy,
                            dlatego nasze projekty współtworzymy z klientami.
                            Działamy lokalnie na terenie Polski oraz pracujemy globalnie.
                        </p>
                        <br/>
                        <br/>
                        <p>
                            Wyróżnia nas zgrany zespół, wysokospecjalistyczny sprzęt, wiedza poparta świadectwem
                            kwalifikacji wspierana przez kreatywne umysły.
                            Stale przyświeca nam innowacyjność a rozwój osobisty to nasze drugie imię.
                            Każdy dzień stawia przed nami nowe wyzwania.
                            Każdego dnia bazując na kilkuletnim doświadczeniu pracujemy na sukces naszych klientów.
                        </p>
                        <br/>
                        <br/>
                        <p>
                            W naszej pracy dokładamy wszelkich starań, by zadowolić rzeszę nawet najbardziej
                            wymagających klientów. Szybką i sprawną realizację projektów oraz właściwe serwisowanie
                            i utrzymanie witryn umożliwia nam niezawodny sprzęt, który oferuje szerokie spektrum
                            możliwości.
                            Zdajemy sobie sprawę jak istotna jest terminowość i przejrzystość w działaniu.
                            Chcesz mieć stronę internetową, która pokazuje indywidualność marki?
                            Skontaktuj się z nami i zaufaj naszym specjalistom!
                        </p>
                    </div>
                    <div className={`my-photo`}/>
                </div>
            </div>
        </div>
    </div>
);

export default AboutUs;
